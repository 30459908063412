define("discourse/plugins/custom trust level/discourse/initializers/plugin-init", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/models/user", "I18n", "discourse/lib/utilities"], function (_exports, _pluginApi, _decorators, _user, _I18n, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = {
    name: "enable-links",
    initialize: function initialize() {
      (0, _pluginApi.withPluginApi)("0.8.33", pluginInit);
    }
  };
  _exports.default = _default;

  function pluginInit(api) {
    var _dec, _obj, _dec2, _obj2;

    api.modifyClass("controller:user", (_dec = (0, _decorators.default)("siteSettings.csl_display_tl0_website_as_link"), (_obj = {
      linkWebsite: function linkWebsite(settingEnabled) {
        return settingEnabled || this._super.apply(this, arguments);
      }
    }, (_applyDecoratedDescriptor(_obj, "linkWebsite", [_dec], Object.getOwnPropertyDescriptor(_obj, "linkWebsite"), _obj)), _obj)));
    var currentUser = api.getCurrentUser();
    var siteSettings = api.container.lookup("site-settings:main");

    if (currentUser && !currentUser.allow_trust_level_upgrade) {
      api.addGlobalNotice("Please read <a href='".concat((0, _utilities.postUrl)(null, parseInt(siteSettings.csl_topic_id_for_trust_level_freeze)), "'>Beginners Guide</a> topic and make sure to give it a like."), "trust-level-freeze-notice", {
        html: "<h3>Your are freezed on a trust level!</h3>"
      });
    }

    api.modifyClass("component:topic-footer-buttons", (_dec2 = (0, _decorators.on)("init"), (_obj2 = {
      subscribe: function subscribe() {
        var _this = this;

        var currentUser = _user.default.current();

        if (currentUser && !this.topic.isPrivateMessage) {
          this.messageBus.subscribe("/".concat(currentUser.id, "/custom_can_create_post"), function (canCreate) {
            _this.set("topic.details.can_create_post", canCreate);
          });
        }
      }
    }, (_applyDecoratedDescriptor(_obj2, "subscribe", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "subscribe"), _obj2)), _obj2)));
  }
});

